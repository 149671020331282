<template>
    <div v-if="office.id" class="pb-4">
        <a class="card shadow-sm-card cursor-pointer text-decoration-none text-dark" :href="'/office/' + office.id">
            <div class="ratio ratio-16x9">
                <img v-if="office.images.length > 0" :src="'data:image;base64,' + office.images[0].image"
                     class="card-img-top object-fit-cover" alt="HousingComplex">
            </div>
            <div class="card-body">
                <h5 v-if="office.title" class="card-title text-truncate">{{ office.subtitle }}</h5>
                <p v-if="office.description" class="card-text text-hc-card">{{ office.description }}</p>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "home-office-card",
    props: {
        office: {
            type: Object,
            default: {}
        }
    }
}
</script>

<style scoped>
.shadow-sm-card {
    transition: all .3s;
}

.shadow-sm-card:hover {
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .125) !important
}

.text-hc-card {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

@media (min-width: 576px) {
    .card-body {
        min-height: 184px
    }
}

</style>
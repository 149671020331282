import {createRouter, createWebHistory} from 'vue-router'
import NotFound from "../views/NotFound";
import Home from "@/views/Home";
import housing_complexes from "@/views/HousingComplexes";
import housing_complex from "@/views/HousingComplex";
import objects from "@/views/Objects.vue";
import Articles from "@/views/Articles";
import Article from "@/views/Article";
import developers from "@/views/Developers";
import developer from "@/views/Developer";
import NewsList from "@/views/NewsList";
import News from "@/views/News";
import Login from "@/views/Login";
import Admin from "@/views/Admin";
import AdminNews from "@/views/AdminNews";
import AdminArticles from "@/views/AdminArticles";
import AdminArticle from "@/views/AdminArticle";
import object from "@/views/Object.vue";
import AdminObjects from "@/views/AdminObjects.vue";
import ImportReports from "@/views/ImportReports.vue";
import ImportReportsList from "@/views/ImportReportsList.vue";
import offices from "@/views/Offices.vue";
import office from "@/views/Office.vue";

const routes = [
    {
        path: '/',
        name: 'Главная страница',
        component: Home
    },
    {
        path: '/hc',
        name: 'Жилые комплексы',
        component: housing_complexes
    },
    {
        path: '/hc/:id',
        name: 'Жилой комплекс',
        component: housing_complex
    },
    {
        path: '/object/:id',
        name: 'Объявление',
        component: object
    },
    {
        path: '/object',
        name: 'Объявления',
        component: objects
    },
    {
        path: '/office/:id',
        name: 'Коммерческое предложение',
        component: office
    },
    {
        path: '/office',
        name: 'Коммерция',
        component: offices
    },
    {
        path: '/developers',
        name: 'Застройщики',
        component: developers
    },
    {
        path: '/developers/:id',
        name: 'Застройщик',
        component: developer
    },
    {
        path: '/articles',
        name: 'Статьи',
        component: Articles
    },
    {
        path: '/articles/:id',
        name: 'Статья',
        component: Article
    },
    {
        path: '/news',
        name: 'Новости',
        component: NewsList
    },
    {
        path: '/news/:id',
        name: 'Новость',
        component: News
    },
    {
        path: '/reports',
        name: 'Выбор файла импорта',
        component: ImportReports
    },
    {
        path: '/report/:filename',
        name: 'Отчеты импорта',
        component: ImportReportsList
    },
    {
        path: '/login',
        name: 'Вход в панель администратора',
        component: Login
    },
    {
        path: '/admin',
        name: 'Панель администратора',
        component: Admin
    },
    {
        path: '/admin/objects',
        name: 'Администрирование объявлений',
        component: AdminObjects
    },
    {
        path: '/admin/news',
        name: 'Администрирование новостей',
        component: AdminNews
    },
    {
        path: '/admin/articles',
        name: 'Администрирование статей',
        component: AdminArticles
    },
    {
        path: '/admin/articles/:id',
        name: 'Администрирование статьи',
        component: AdminArticle
    },
    {
        path: '/admin/news/:id',
        name: 'Администрирование новости',
        component: AdminArticle
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404 NotFound',
        component: NotFound,
    }
]

// noinspection JSCheckFunctionSignatures
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to) => {
    window.document.title = to.name ? to.name + ' | p-l-e.ru' : 'p-l-e.ru'
})

/*
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta)

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next()

    // Turn the meta tag definitions into actual elements in the head.
    // noinspection JSUnresolvedFunction
    nearestWithMeta.meta.map(tagDef => {
        const tag = document.createElement('meta')

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key])
        })

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '')

        return tag
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag))

    next()
})*/

export default router

<template>
    <offices-search
        :metro="filter.metro"
        :how="filter.how"
        :minCost="filter.minCost"
        :maxCost="filter.maxCost"
        :minSquareMeter="filter.minSquareMeter"
        :maxSquareMeter="filter.maxSquareMeter"
        :available="filter.available"
        :type="filter.type"
        :classOffice="filter.classOffice"
        @newSearch="updateGetObjects"
    />

    <div v-for="item in office.objects">
        <offices-card :object="item"/>
    </div>

    <pagination
        :number="10"
        :page="filter.page"
        :total="office.total"
        @newPagination="updatePage"
    />
</template>

<script>
import Pagination from "@/components/UI/Pagination.vue";
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import OfficesSearch from "@/components/pages/commercial/OfficesSearch.vue";
import useOffices from "@/hooks/useOffices";
import OfficesCard from "@/components/pages/commercial/OfficesCard.vue";

export default {
    name: "offices",
    components: {OfficesCard, OfficesSearch, Pagination},
    setup() {
        const route = useRoute()
        const router = useRouter()

        const {getOffices, office} = useOffices()

        onMounted(async () => {
            await router.replace({
                query: myQuery()
            })
            await getOffices()
        })

        const filter = ref({
            region: route.query.region ?? "",
            metro: route.query.metro ?? "",
            how: route.query.how ?? "",
            minCost: route.query.minCost ?? "",
            maxCost: route.query.maxCost ?? "",
            minSquareMeter: route.query.minSquareMeter ?? "",
            maxSquareMeter: route.query.maxSquareMeter ?? "",
            page: Number(route.query.page) ? Number(route.query.page) : 1,
            type: route.query.type ?? "",
            classOffice: route.query.classOffice ?? "",
        })

        const myQuery = () => {
            return {
                metro: filter.value.metro,
                how: filter.value.how,
                minCost: filter.value.minCost,
                maxCost: filter.value.maxCost,
                minSquareMeter: filter.value.minSquareMeter,
                maxSquareMeter: filter.value.maxSquareMeter,
                page: filter.value.page,
                type: filter.value.type,
                classOffice: filter.value.classOffice,
            }
        }

        const updatePage = async (page) => {
            filter.value.page = page
            await router.replace({
                query: myQuery()
            })
            location.reload()
        }

        const updateGetObjects = async (
            metro,
            how,
            minCost,
            maxCost,
            minSquareMeter,
            maxSquareMeter,
            type,
            classOffice
        ) => {
            filter.value.metro = metro
            filter.value.how = how
            filter.value.minCost = minCost
            filter.value.maxCost = maxCost
            filter.value.minSquareMeter = minSquareMeter
            filter.value.maxSquareMeter = maxSquareMeter
            filter.value.page = 1
            filter.value.type = type
            filter.value.classOffice = classOffice
            await router.replace({
                query: myQuery()
            })

            location.reload()
        }

        return {updateGetObjects, updatePage, office, filter}
    }
}
</script>
<style scoped>

</style>
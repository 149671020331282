<template>
  <hc-search
      @newSearch="newSearch"
  />
  <home-hc/>
  <home-office/>
  <home-articles/>
  <home-developer/>
  <p class="small pt-5">На портале собрана самая полная и актуальная информация о жилищном строительстве.
    Есть информация о каждом объекте недвижимости, который находится на стадии строительства или уже сданным в
    эксплуатацию на территории России. Наша цель – помочь людям в правильном выборе квартиры в новостройке на территории
    страны от самых лучших строительных компаний.</p>
</template>

<script>
import HomeHc from "@/components/pages/home/HomeHC";
import Header from "@/components/pages/home/PLEHeader";
import HomeArticles from "@/components/pages/home/HomeArticles";
import HomeDeveloper from "@/components/pages/home/HomeDeveloper";
import HcSearch from "@/components/pages/hc/HcSearch";
import HomeOffice from "@/components/pages/home/HomeOffice.vue";

export default {
  name: "home",
  components: {HomeOffice, HcSearch, HomeDeveloper, HomeArticles, Header, HomeHc},
  setup() {
    const newSearch = (
        name,
        metro,
        deadline,
        how,
        minCostApartment,
        maxCostApartment,
        minCostSquareMeter,
        maxCostSquareMeter,
        available
    ) => {
      window.location.href = '/hc' +
          "?name=" + name +
          "&metro=" + metro +
          "&deadline=" + deadline +
          "&how=" + how +
          "&min_ca=" + minCostApartment +
          "&max_ca=" + maxCostApartment +
          "&min_csm=" + minCostSquareMeter +
          "&max_csm=" + maxCostSquareMeter +
          "&available=" + available
    }

    return {newSearch}
  }
}
</script>

<style scoped>

</style>
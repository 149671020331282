import {ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {serverUrl} from "@/App.vue";
import router from "@/router";
import useTokens from "@/hooks/useTokens";

export default function useOffices() {
    const route = useRoute()
    const {authHeader, logout} = useTokens()
    const selectedCity = localStorage.getItem('selectedCity');

    const officeLoader = ref(false)
    const officeException = ref("")
    const office = ref([])
    const officeId = ref({
        type: null,
        category: null,
        area: null,
        renovation: null,
        quality: null,
        description: "",
        rooms: null,
        floor: null,
        count: 0,
        region: null,
        address: null,
        metro: null,
        latitude: null,
        longitude: null,
        images: []
    })

    const getHomeOffice = async () => {
        officeException.value = ""
        officeLoader.value = true

        await axios.get(
            serverUrl + `offices?number=6&selectedCity=${selectedCity}`
        )
            .then(response => {
                office.value = response.data
            })
            .catch(async e => {
                try {
                    officeException.value = e.response.data.error
                } catch {
                    officeException.value = "Exception"
                }
            })
            .finally(() => officeLoader.value = false)
    }

    const getOffices = async () => {
        officeException.value = ""
        officeLoader.value = true

        const params = {
            ...route.query,
            selectedCity: selectedCity
        };

        await axios.get(
            serverUrl + `offices`, {params}
        )
            .then(response => {
                office.value = response.data
            })
            .catch(async e => {
                try {
                    officeException.value = e.response.data.error
                } catch {
                    officeException.value = "Exception"
                }
            })
            .finally(() => officeLoader.value = false)
    }

    const getOfficeId = async (id) => {
        officeException.value = ""
        officeLoader.value = true

        console.log(id)
        await axios.get(
            serverUrl + `offices/` + id
        )
            .then(response => {
                officeId.value = response.data
            })
            .catch(async e => {
                try {
                    officeException.value = e.response.data.error
                } catch {
                    officeException.value = "Exception"
                }
            })
            .finally(() => officeLoader.value = false)
    }

    return {getHomeOffice, getOffices, getOfficeId, office, officeId}
}
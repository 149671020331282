<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a class="link-secondary" @click="$router.push('/admin')">
                    Панель администратора
                </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Администрирование объявлений</li>
        </ol>
    </nav>

    <form v-if="!is_started" @submit.prevent="submitFile">
        <input type="file" @change="onFileChange">
        <button class="btn btn-success" type="submit">Импорт</button>
    </form>

    <div v-if="is_started && !is_error">
        <h2>
            Загрузка файлов на сервер
        </h2>
        <div class="progress-bar-container">
            <div class="progress-bar text-center" :style="{ width: progress + '%'}">

            </div>
            <span class="progress-text">{{ Math.round(process / all_line * 100) }} %</span>
        </div>
        <p>Загружено {{ process }} / Всего {{ all_line }}</p>

        <div>
            <p>Прошло времени: {{ elapsedTime }}</p>
            <p>Оставшееся время: {{ remainingTime }}</p>
        </div>

        <div>
            <h5>
                Отчет по импорту
                <p>
                    <a href="#" @click="copyTextToClipboard()">Скопировать в буфер</a>
                </p>
                <p>
                    <a href="#" @click="downloadFileXmlReport()">Скачать файлом</a>
                </p>
            </h5>
            <span id="report_info">
                {{ reportXml }}
            </span>
        </div>

        <div v-if="process === all_line">
            <button class="btn btn-primary" @click="resetProgress()">
                Загрузить новый файл
            </button>
        </div>
    </div>

    <div v-if="is_error">
        <h2 style="color: red">
            При загрузке файлов произошла ошибка
        </h2>
        <h5>{{ error_message }}</h5>
    </div>
</template>

<script>
import {serverUrl} from "@/App.vue";
import axios from 'axios';

export default {
    data() {
        return {
            file: null,
            fileName: null,
            process: 0,
            all_line: 0,
            is_started: false,
            is_error: false,
            error_message: null,
            now: Date.now(),
            startTime: null,
            report_lines: [],

        }
    },
    mounted() {
        this.interval = setInterval(() => this.now = Date.now(), 1000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    computed: {
        progress() {
            return (this.process / this.all_line) * 100;
        },
        elapsedTime() {
            if (!this.startTime) return 0;
            const totalSeconds = Math.floor((this.now - this.startTime) / 1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            if (minutes === 0) {
                return `${seconds} сек`;
            } else {
                return `${minutes} мин ${seconds} сек`;
            }
        },
        remainingTime() {
            if (!this.startTime || this.completed === 0) return 'N/A';
            const avgTimePerItem = (Date.now() - this.startTime) / this.process;
            const totalSeconds = Math.floor(avgTimePerItem * (this.all_line - this.process) / 1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return `${minutes} мин ${seconds} сек`;
        },
        reportXml() {
            let text = "<result>\n";
            this.report_lines.forEach((line) => {
                text += `<ad url="https://p-l-e.ru/object/` + line + `" id="` + line + `"/>\n`;
            })
            text += "</result>\n";

            return text;
        }
    },
    methods: {
        copyTextToClipboard() {
            const text = document.getElementById("report_info").innerText;

            navigator.clipboard.writeText(text).then(function() {
                alert('Текст успешно скопирован в буфер обмена');
            }, function(err) {
                console.error('Произошла ошибка при копировании текста: ', err);
            });
        },
        downloadFileXmlReport() {
            let xmlCode = document.getElementById("report_info").innerText;

            // Создание файла report.xml
            const blob = new Blob([xmlCode], {type: 'text/xml'});
            const url = URL.createObjectURL(blob);

            // Создание ссылки для скачивания
            const a = document.createElement('a');
            a.href = url;
            a.download = 'report.xml';
            document.body.appendChild(a);

            // Нажатие на ссылку для начала скачивания
            a.click();

            // Удаление ссылки
            document.body.removeChild(a);
        },
        onFileChange(e) {
            this.file = e.target.files[0];
            this.fileName = this.file.name.split('.').slice(0, -1).join('.');
        },
        async submitFile() {
            const reader = new FileReader();
            let vm = this;

            reader.onload = function(event) {
                const xmlString = event.target.result;
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(xmlString, "text/xml");

                const offers = xmlDoc.getElementsByTagName('offer');

                const data = Array.from(offers).map(offer => {
                    const metros = offer.getElementsByTagName("metro");
                    const metroData = Array.from(metros).map(metro => {
                        return {
                            name: metro.getElementsByTagName('name')[0]?.textContent,
                            timeOnTransport: metro.getElementsByTagName('time-on-transport')[0]?.textContent
                        };
                    });

                    const sales_agent = offer.getElementsByTagName('sales-agent');
                    const salesData = Array.from(sales_agent).map(agent => {
                        return {
                            agent_name: agent.getElementsByTagName('name')[0]?.textContent,
                            agent_phone: agent.getElementsByTagName('phone')[0]?.textContent,
                            agent_category: agent.getElementsByTagName('category')[0]?.textContent,
                            agent_organization: agent.getElementsByTagName('organization')[0]?.textContent,
                            agent_url: agent.getElementsByTagName('url')[0]?.textContent,
                            agent_email: agent.getElementsByTagName('email')[0]?.textContent,
                            agent_photo: agent.getElementsByTagName('photo')[0]?.textContent,
                        };
                    });

                    const price = offer.getElementsByTagName('price');
                    const priceData = Array.from(price).map(price => {
                        return {
                            price_value: price.getElementsByTagName('value')[0]?.textContent,
                        };
                    });

                    const images = offer.getElementsByTagName("image");
                    const imageData = Array.from(images).map(image => {
                        return image.textContent;
                    });

                    let arias = offer.getElementsByTagName("area");
                    let areaData = Array.from(arias).map(area => {
                        return {
                            value: area.getElementsByTagName('value')[0]?.textContent,
                            unit: area.getElementsByTagName('unit')[0]?.textContent,
                        };
                    });

                    if (typeof areaData[0] === "undefined") {
                        arias = offer.getElementsByTagName("lot-area");
                        areaData = Array.from(arias).map(area => {
                            return {
                                value: area.getElementsByTagName('value')[0]?.textContent,
                                unit: area.getElementsByTagName('unit')[0]?.textContent,
                            };
                        });
                    }

                    return {
                        id: offer.getAttribute('internal-id'),
                        type: offer.getElementsByTagName('type')[0]?.textContent,
                        propertyType: offer.getElementsByTagName('property-type')[0]?.textContent,
                        category: offer.getElementsByTagName('category')[0]?.textContent,
                        url: offer.getElementsByTagName('url')[0]?.textContent,
                        payedAdv: offer.getElementsByTagName('payed-adv')[0]?.textContent,
                        manuallyAdded: offer.getElementsByTagName('manually-added')[0]?.textContent,
                        creationDate: offer.getElementsByTagName('creation-date')[0]?.textContent,
                        lastUpdateDate: offer.getElementsByTagName('last-update-date')[0]?.textContent,
                        expireDate: offer.getElementsByTagName('expire-date')[0]?.textContent,
                        country: offer.getElementsByTagName('country')[0]?.textContent,
                        region: offer.getElementsByTagName('region')[0]?.textContent,
                        district: offer.getElementsByTagName('district')[0]?.textContent,
                        address: offer.getElementsByTagName('address')[0]?.textContent,
                        localityName: offer.getElementsByTagName('locality-name')[0]?.textContent,
                        latitude: offer.getElementsByTagName('latitude')[0]?.textContent,
                        longitude: offer.getElementsByTagName('longitude')[0]?.textContent,
                        metro: metroData,
                        agentName: salesData[0].agent_name,
                        agentPhone: salesData[0].agent_phone,
                        agentCategory: salesData[0].agent_category,
                        agentOrganization: salesData[0].agent_organization,
                        agentUrl: salesData[0].agent_url,
                        agentEmail: salesData[0].agent_email,
                        agentPhoto: salesData[0].agent_photo,
                        price: priceData[0].price_value,
                        mortgage: offer.getElementsByTagName('mortgage')[0]?.textContent,
                        dealStatus: offer.getElementsByTagName('deal-status')[0]?.textContent,
                        images: imageData,
                        description: offer.getElementsByTagName('description')[0]?.textContent,
                        area: areaData[0].value,
                        areaUnit: areaData[0].unit,
                        livingSpace: offer.getElementsByTagName('living-space')[0]?.textContent,
                        roomSpace: offer.getElementsByTagName('room-space')[0]?.textContent,
                        studio: offer.getElementsByTagName('studio')[0]?.textContent,
                        newFlat: offer.getElementsByTagName('new-flat')[0]?.textContent,
                        rooms: offer.getElementsByTagName('rooms')[0]?.textContent,
                        roomsType: offer.getElementsByTagName('rooms-type')[0]?.textContent,
                        bathroomUnit: offer.getElementsByTagName('bathroom-unit')[0]?.textContent,
                        floorCovering: offer.getElementsByTagName('floor-covering')[0]?.textContent,
                        windowView: offer.getElementsByTagName('window-view')[0]?.textContent,
                        floor: offer.getElementsByTagName('floor')[0]?.textContent,
                        floorsTotal: offer.getElementsByTagName('floors-total')[0]?.textContent,
                        buildingName: offer.getElementsByTagName('building-name')[0]?.textContent,
                        yandexBuildingId: offer.getElementsByTagName('yandex-building-id')[0]?.textContent,
                        yandexHouseId: offer.getElementsByTagName('yandex-house-id')[0]?.textContent,
                        buildingType: offer.getElementsByTagName('building-type')[0]?.textContent,
                        buildingSeries: offer.getElementsByTagName('building-series')[0]?.textContent,
                        buildingSection: offer.getElementsByTagName('building-section')[0]?.textContent,
                        buildingState: offer.getElementsByTagName('building-state')[0]?.textContent,
                        builtYear: offer.getElementsByTagName('built-year')[0]?.textContent,
                        readyQuarter: offer.getElementsByTagName('ready-quarter')[0]?.textContent,
                        lift: offer.getElementsByTagName('lift')[0]?.textContent,
                        rubbishChute: offer.getElementsByTagName('rubbish-chute')[0]?.textContent,
                        parking: offer.getElementsByTagName('parking')[0]?.textContent,
                        ceilingHeight: offer.getElementsByTagName('ceiling-height')[0]?.textContent,
                        videoReview: offer.getElementsByTagName('video-review')[0]?.textContent
                    };
                });

                vm.sendFactory(data);
            };
            reader.readAsText(this.file);
        },
        chunkArray(array, size) {
            let result = [];
            for (let i = 0; i < array.length; i += size) {
                result.push(array.slice(i, i + size));
            }
            return result;
        },
        async sendFactory(data) {
            this.is_started = true;
            this.all_line = data.length;
            this.startTime = Date.now();
            this.report_lines = [];

            const id = this.generateRandomNumberString();
            let chunkArray = this.chunkArray(data, 1);

            let vm = this;
            for (let item of chunkArray) {
                await vm.sendData(item, id);
            }
        },
        generateRandomNumberString() {
            const numbers = '0123456789';
            let result = '';
            const numbersLength = numbers.length;
            for (let i = 0; i < 6; i++) {
                result += numbers.charAt(Math.floor(Math.random() * numbersLength));
            }
            return result;
        },
        sendData(data, id) {
            data[0]['reportId'] = parseInt(id);
            data[0]['fileName'] = this.fileName;
            return new Promise((resolve, reject) => { // Возвращаем новый промис
                axios.post(serverUrl + '/objects/upload', data)
                    .then(response => {
                        this.report_lines.push(data[0].id);
                        this.process += data.length;
                        resolve(); // Разрешаем промис, когда this.process обновлен
                    })
                    .catch(error => {
                        this.is_error = true;
                        this.error_message = error.message;
                        reject(error); // Отклоняем промис, если возникла ошибка
                    });
            });
        },
        resetProgress() {
            this.is_started = false;
            this.process = 0;
            this.all_line = 0;
        }
    }
}
</script>

<style scoped>
.progress-bar-container {
    width: 100%;
    height: 20px;
    background-color: #f3f3f3;
    text-align: center;
    display: flow;
}

.progress-bar {
    height: 100%;
    background-color: green;
    font-weight: bold;
}

.progress-text {
    position: relative;
    top: -22px;
    font-weight: bold;
}

#report_info {
    white-space: pre-line;
}
</style>
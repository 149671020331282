<template>
    <!-- Карусель для фотографий -->
    <div v-if="officeId.images.length > 0" id="carouselOffice" class="carousel slide carousel-fade pb-3">
        <div class="carousel-inner">
            <div class="carousel-item ratio ratio-16x9 active">
                <img :src="'data:image;base64,' + officeId.images[0].image" class="d-block w-100 rounded object-fit-fill"
                     :alt="officeId.title">
            </div>
            <div v-for="(item, index) in officeId.images.slice(1)" :key="index" class="carousel-item ratio ratio-16x9">
                <img :src="'data:image;base64,' + item.image" class="d-block w-100 rounded object-fit-fill" :alt="officeId.title">
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselOffice" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselOffice" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>

    <!-- Заголовок и подзаголовок -->
    <div class="py-3">
        <h1 class="fw-bold">{{ officeId.title }}</h1>
        <p v-if="officeId.subterm" class="lead">
            <i class="bi bi-info-circle me-2"></i>{{ getSubtermFormated(officeId.subterm) }}
        </p>
    </div>

    <!-- Основная информация -->
    <h2 v-if="officeId.type && officeId.address" class="py-3">
        {{ getType(officeId.type) }} офиса {{ officeId.area }} м² по цене {{ officeId.pricetotal.toLocaleString() }} ₽/мес
    </h2>

    <div class="row pb-3">
        <!-- Площадь -->
        <div v-if="officeId.area" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-rulers fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    Площадь <b>{{ officeId.area }} м<sup>2</sup></b>
                </span>
            </div>
        </div>

        <!-- Этаж -->
        <div v-if="officeId.floor" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-building fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    <b>{{ officeId.floor }}</b> этаж из <b>{{ officeId.floortotal }}</b>
                </span>
            </div>
        </div>

        <!-- Цена -->
        <div v-if="officeId.price" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-wallet2 fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    <b>{{ officeId.price.toLocaleString() }}</b> ₽
                </span>
            </div>
        </div>

        <!-- Класс офиса -->
        <div v-if="officeId.buildingclass" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-star-fill fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    Класс <b>{{ formatClass(officeId.buildingclass) }}</b>
                </span>
            </div>
        </div>

        <!-- Адрес -->
        <div v-if="officeId.address" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-map fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    Адрес: <b>{{ officeId.address }}</b>
                </span>
            </div>
        </div>

        <!-- Метро -->
        <div v-if="officeId.metro && officeId.metro.length > 0" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-train-front fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <div v-for="metroItem in officeId.metro" :key="metroItem.name">
                    <span>
                        Метро: <b>{{ metroItem.metro }} - {{ metroItem.time }} мин</b>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- Список площадей (offices_area) -->
    <div v-if="officeId.areas && officeId.areas.length > 0" class="mb-4">
        <h4 class="fw-bold mb-3">Помещения в офисе</h4>
        <div class="row">
            <div v-for="area in officeId.areas" :key="area.id" class="col-md-6 mb-3">
                <div class="card h-100">
                    <div class="card-body">
                        <h5 class="card-title fw-bold">Помещение {{ area.id }}</h5>
                        <p class="card-text">
                            <i class="bi bi-rulers me-2"></i>Площадь: <b>{{ area.area }} м²</b><br>
                            <i class="bi bi-wallet2 me-2"></i>Цена: <b>{{ area.price.toLocaleString() }} ₽</b><br>
                            <i class="bi bi-building me-2"></i>Этажи:
                            <b v-if="area.floorFrom === area.floorTo">{{ area.floorFrom }}</b>
                            <b v-else>{{ area.floorFrom }} - {{ area.floorTo }}</b><br>
                            <i class="bi bi-cash-coin me-2"></i>Цена за м²: <b>{{ area.pricePerMeterRur.toLocaleString() }} ₽</b><br>
                            <i v-if="area.pricePerMeterPerYearRur" class="bi bi-calendar me-2"></i>
                            <span v-if="area.pricePerMeterPerYearRur">
                                Цена за м² в год: <b>{{ area.pricePerMeterPerYearRur.toLocaleString() }} ₽</b>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr>

    <!-- Карта -->
    <div class="block_map" v-if="officeId.latitude && officeId.longitude">
        <l-map :zoom="zoom" :center="[officeId.latitude, officeId.longitude]">
            <l-tile-layer :url="url"></l-tile-layer>
            <l-marker :lat-lng="[officeId.latitude, officeId.longitude]"></l-marker>
        </l-map>
    </div>

    <hr>

    <!-- Информация о продавце -->
    <div class="agent_block">
        <h5>Информация о продавце</h5>

        <div v-if="officeId.company" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-building fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    Компания: <b>{{ officeId.company }}</b>
                </span>
            </div>
        </div>

        <div v-if="officeId.phone" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-telephone-fill fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    <a :href="'tel:' + officeId.phone">
                        <b>{{ officeId.phone }}</b>
                    </a>
                </span>
            </div>
        </div>
    </div>

    <hr>

    <!-- Описание -->
    <p v-if="officeId.description" class="pt-3">{{ replaceDescription(officeId.description) }}</p>
</template>

<script>
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import { LMap, LTileLayer, LMarker } from 'vue3-leaflet';
import useOffices from "@/hooks/useOffices";

export default {
    name: "office",
    setup() {
        const route = useRoute();
        const id = route.params.id;
        const zoom = ref(15);
        const url = ref('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');

        const { getOfficeId, officeId } = useOffices();
        onMounted(async () => {
            await getOfficeId(id);
        });

        return { officeId, zoom, url };
    },
    components: {
        LMap,
        LTileLayer,
        LMarker
    },
    methods: {
        replaceDescription(text) {
            return text.replace(/&quot;/g, '"');
        },
        getType(type) {
            switch (type) {
                case "SALE":
                case "продажа":
                    return "Продажа";
                default:
                case "RENT":
                case "аренда":
                    return "Аренда";
            }
        },
        getSubtermFormated(text) {
            return text.replace(/м2/g, 'м²');
        },
        formatClass(className) {
            return className.replace('Plus', '+').toUpperCase();
        }
    },
};
</script>

<style scoped>
.carousel-control-prev-icon, .carousel-control-next-icon {
    filter: invert(100%);
}

.block_map {
    height: 400px !important;
}

.card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.card:hover {
    transform: translateY(-5px);
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.card-text {
    font-size: 0.9rem;
    color: #555;
}

.lead {
    font-size: 1.1rem;
    color: #666;
}
</style>
<template>
    <a class="card office-card shadow-sm-card cursor-pointer mb-3 text-decoration-none text-dark" :href="'/office/' + object.id">
        <div class="row g-0">
            <!-- Карусель для фотографий -->
            <div class="col-md-4">
                <div v-if="object.images.length > 0" class="ratio ratio-1x1">
                    <img :src="'data:image;base64,' + object.images[0].image" class="img-fluid rounded-ple object-fit-cover" alt="Объявление">
                </div>
            </div>

            <!-- Основная информация -->
            <div class="col-md-8">
                <div class="card-body">
                    <!-- Заголовок и подзаголовок -->
                    <h5 class="card-title fw-bold">{{ object.title }}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{ object.subtitle }}</h6>

                    <!-- Тип (аренда/продажа) -->
                    <div class="mb-2">
                        <span class="badge bg-primary">{{ getType(object.type) }}</span>
                    </div>

                    <!-- Адрес и карта -->
                    <div class="mb-3">
                        <p class="card-text address" @click.prevent="getMap">
                            <i class="bi bi-geo-alt-fill me-2"></i>{{ object.address }}
                            <span class="text-primary">(показать на карте)</span>
                        </p>
                        <div v-if="isViewMap" class="map_here" @click.prevent="null">
                            <l-map class="block_map" :zoom="zoom" :center="[object.latitude, object.longitude]">
                                <l-tile-layer :url="url"></l-tile-layer>
                                <l-marker :lat-lng="[object.latitude, object.longitude]"></l-marker>
                            </l-map>
                        </div>
                    </div>

                    <!-- Описание -->
                    <p v-if="object.description" class="card-text text-muted">{{ sliceDescription(object.description) }}</p>

                    <!-- Детали офиса -->
                    <div class="row mb-3">
                        <div v-if="object.area" class="col-6 pb-2">
                            <i class="bi bi-rulers me-2"></i>{{ object.area }} м²
                        </div>
                        <div v-if="object.floor" class="col-6 pb-2">
                            <i class="bi bi-building me-2"></i>{{ object.floor }} этаж из {{ object.floortotal }}
                        </div>
                        <div v-if="object.pricetotal" class="col-6 pb-2">
                            <i class="bi bi-wallet2 me-2"></i>{{ formatPrice(object.pricetotal) }} ₽
                        </div>
                        <div v-if="object.buildingclass" class="col-6 pb-2">
                            <i class="bi bi-star-fill me-2"></i>Класс {{ formatClass(object.buildingclass) }}
                        </div>
                        <div v-if="object.company" class="col-6 pb-2">
                            <i class="bi bi-building me-2"></i>Компания: {{ object.company }}
                        </div>
                        <div v-if="object.phone" class="col-6 pb-2">
                            <i class="bi bi-telephone-fill me-2"></i>{{ object.phone }}
                        </div>
                    </div>

                    <!-- Дополнительная информация -->

                </div>
            </div>
        </div>
    </a>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue3-leaflet';
import { ref } from "vue";

export default {
    name: "OfficesCard",
    components: {
        LMap,
        LTileLayer,
        LMarker
    },
    props: {
        object: {
            type: Object,
            default: () => ({})
        }
    },
    setup() {
        const zoom = ref(15);
        const url = ref('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
        const activeImageIndices = ref({}); // Хранит активный индекс для каждой карточки

        return {
            zoom,
            url,
            activeImageIndices,
        };
    },
    mounted() {
        // Обновляем активный индекс при смене слайда
        const carousel = document.getElementById('officeCarousel' + this.object.id);
        if (carousel) {
            carousel.addEventListener('slid.bs.carousel', (event) => {
                this.activeImageIndices[this.object.id] = event.to;
            });
        }
    },
    data() {
        return {
            isViewMap: false
        };
    },
    computed: {
        // Уникальные метро с минимальным временем
        uniqueMetro() {
            const metroMap = new Map();
            this.object.metro.forEach(item => {
                if (!metroMap.has(item.metro) || item.time < metroMap.get(item.metro).time) {
                    metroMap.set(item.metro, item);
                }
            });
            return Array.from(metroMap.values());
        }
    },
    methods: {
        formatPrice(num) {
            if (num < 1000000) {
                return (num / 1000).toFixed(1) + " тыс.";
            } else {
                return (num / 1000000).toFixed(1) + " млн.";
            }
        },
        formatClass(className) {
            return className.replace('Plus', '+').toUpperCase();
        },
        sliceDescription(text) {
            const length_text = 200;
            text = text.replace(/&quot;/g, '"');

            if (text.length > length_text) {
                return text.slice(0, length_text) + "...";
            }

            return text;
        },
        getSubtermFormated(text) {
            return text.replace(/м2/g, 'м²');
        },
        getMap() {
            this.isViewMap = !this.isViewMap;
        },
        getType(type) {
            switch (type) {
                case "sale":
                case "продажа":
                    return "Продажа";
                default:
                case "rent":
                case "аренда":
                    return "Аренда";
            }
        },
    }
};
</script>

<style scoped>
.shadow-sm-card {
    transition: all 0.3s;
}

.shadow-sm-card:hover {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.125) !important;
}

.address:hover {
    cursor: pointer;
    text-decoration: underline;
}

.leaflet-container {
    height: 300px !important;
}

.badge {
    font-size: 0.875em;
    padding: 0.25em 0.5em;
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.card-subtitle {
    font-size: 1rem;
    color: #6c757d;
}

.card-text {
    font-size: 0.875rem;
    color: #6c757d;
}

.text-primary {
    color: #0d6efd !important;
}

.carousel-indicators button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
}
</style>
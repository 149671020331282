<template>

    <div class="row">
        <!--suppress JSUnresolvedVariable -->
        <div v-for="item in office.objects" class="col-md-4 col-sm-6 col-12">
            <home-office-card :office="item"/>
        </div>
    </div>

</template>

<script>
import useHc from "@/hooks/useHc";
import home_hc_card from "@/components/pages/home/HomeHcCard";
import {onMounted} from "vue";
import useOffices from "@/hooks/useOffices";
import HomeOfficeCard from "@/components/pages/home/HomeOfficeCard.vue";

export default {
    name: "home-office",
    components: {HomeOfficeCard, home_hc_card},
    setup() {
        const {getHomeOffice, office} = useOffices()

        onMounted(getHomeOffice)

        return {office}
    }
}
</script>

<style scoped>

</style>